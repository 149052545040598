const mm = {
  // Base
  continue: "Continue",
  paid: "Paid",
  unpaid: "Unpaid",
  partiallyPaid: "Partially Paid",
  // navbar
  shoppingCart: "စျေးခြင်းတောင်း",
  loginAndRegister: "အဝင် / အထွက်",
  // Hero
  product: "Product",
  // Product Section
  productCategories: "Products",
  thereIsNoProduct: "There is No Product",
  favFlavor: "Fav Flavor",
  productYouMustTry: "Product You Must Try",
  // Footer
  followUsOn: "Follow Us On",
  powerBy360Digital: "Power By 360 Digital",
  aboutUs: "About Us",
  products: "Products",
  recipe: "Recipe",
  blogs: "Blogs",
  careers: "Careers",
  contactUs: "Contact Us",
  // Shopping Cart
  totalAmount: "Total Amount",
  continueShopping: "Continue Shopping",
  checkout: "Checkout",
  // Checkout Section
  // Checkout Options
  checkoutOptions: "Checkout Options",
  // Register Account
  registerAccount: "Register Account",
  yourName: "Your Name",
  email: "E-Mail",
  phno: "Ph.No",
  password: "Password",
  state: "State",
  city: "City",
  country: "Country",
  detailAddress: "Detail Address",
  myAddressAndDeliveryAddressAreSame:
    "My Address and delivery address are same.",
  // Return Customer
  returnCustomer: "Return Customer",
  forgetPassword: "Forget Password",
  // Billing Detail
  billingDetail: "Billing Detail",
  selectPaymentMethod: "Select Payment Method",
  cash: "Cash",
  mobileBanking: "Mobile Banking",
  // Cash
  orderTotal: "Order Total",
  remark: "Remark",
  delifreeabout: "Deliver fee free about 10,000 MMK",
  makePayment: "Make Payment",
  // Mobile Banking
  accountName: "Account Name",
  accountNumber: "Account Number",
  addPaymentScreenshot: "Add Payment Screenshot",
  addImageOrPasteHere: "Add image or Paste Here",
  termAndPermission: "I agress to the term and privacy policy of PEP",
  // Confirm Order
  confirmOrder: "Confirm Order",
  // Account Detail
  accountDetials: "Account Detials",
  ordersHistory: "Orders History",
  Logout: "Logout",
  // Edit Profile
  editProfile: "Edit Profile",
  saveChange: "Save Change",
  // Order History
  myOrders: "My Orders",
  invoiceno: "Invoice No.",
  date: "Date",
  order: "Order",
  status: "Status",
  csName: "Cs.Name",
  // Logout
  logout: "Logout",
};

export default mm;
