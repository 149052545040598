import axios from "axios";
import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { API_END_POINT, MAIN_API_END_POINT } from "../constant/data";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

function SignUpForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState([]);
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });

  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone: "",
    avatar: "/media/default.png",
    gender: "",
    password: "",
    password_confirm: "",
    store: "",
    username: "",
    orders: [],
    tags: [],
    customer_addresses: [
      {
        state: "",
        address: "",
        city: "",
        map: "",
        default: true,
      },
    ],
  });

  useEffect(() => {
    const fetchStoreData = async () => {
      const response = await axios.get(`${API_END_POINT}web/store/`);
      setStore(response.data);
    };
    fetchStoreData();
  }, []);

  const CreateCustomer = async () => {
    customer.name = name.firstName + " " + name.lastName;
    customer.username =
      customer.name.toLowerCase().replaceAll(" ", "_") + "_" + uuidv4();
    customer.store = store.id;
    try {
      const { data } = await axios.post(
        `${MAIN_API_END_POINT}web/customer/detail/`,
        { data: customer }
      );
      const customerResponse = await axios.get(
        `${MAIN_API_END_POINT}web/customer/detail/?email=${data.email}`
      );
      localStorage.setItem("customer", JSON.stringify(customerResponse.data));

      navigate("/");
      window.location.reload();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <section className="h-screen flex justify-center items-center m-0 overflow-hidden">
      <img
        onClick={() => {
          navigate("https://dev.360-digital.net/pep/");
        }}
        src="/media/images/pep/web-img/logo.png"
        className=" cursor-pointer fixed left-8 top-5"
        alt="logo"
      />
      <div className="flex w-full h-screen">
        <div className="w-full flex flex-rows justify-center items-center">
          <div className="w-full max-lg:w-[80%] max-sm:w-[100%] px-10 lg:pr-16">
            <h1 className="font-Annifont text-4xl text-[var(--text-color)]">
              Sign up
            </h1>
            <p className="font-Cushy_W01 text-[var(--text-color)] text-sm pt-4 pb-6">
              New to salespoint? Please enter your details.
            </p>
            <form action="">
              <div className="grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6 mb-4">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="firstname"
                    className="text-[var(--text-color)] font-Poppins"
                  >
                    First Name
                  </label>
                  <div className="mt-2">
                    <input
                      value={name.firstName}
                      onChange={(e) => {
                        setName({
                          ...name,
                          firstName: e.target.value,
                        });
                      }}
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                      placeholder="Your name"
                      required
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="lastname"
                    className="text-[var(--text-color)] font-Poppins"
                  >
                    Last Name
                  </label>
                  <div className="mt-2">
                    <input
                      value={name.lastName}
                      onChange={(e) => {
                        setName({
                          ...name,
                          lastName: e.target.value,
                        });
                      }}
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                      placeholder="Your name"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Email
                </label>
                <input
                  value={customer.email}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      email: e.target.value,
                    });
                  }}
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Telephone
                </label>
                <input
                  value={customer.phone_number}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      phone: e.target.value,
                    });
                  }}
                  type="number"
                  name="phone"
                  id="phone"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Telephone"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Password
                </label>
                <input
                  value={customer.password}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      password: e.target.value,
                    });
                  }}
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Enter your password"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password-confirm"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Password Confirm
                </label>
                <input
                  value={customer.password_confirm}
                  onChange={(e) => {
                    setCustomer({
                      ...customer,
                      password_confirm: e.target.value,
                    });
                  }}
                  type="password"
                  name="password-confirm"
                  id="password-confirm"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-[#696969] placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Password confirm"
                  required
                />
              </div>

              <div className="flex flex-col gap-2 mb-4">
                <label className="text-[var(--text-color)] font-Poppins">
                  Subscribe to Newsletter
                </label>
                <div className="font-Cushy_W01 text-[var(--text-color)] flex items-center gap-5">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="yes-btn"
                      name="subscribe"
                      className="custom-signupform-radio"
                      required
                    />
                    <label htmlFor="yes-btn">Yes</label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="no-btn"
                      name="subscribe"
                      className="custom-signupform-radio"
                      required
                    />
                    <label htmlFor="no-btn">No</label>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p
                  onClick={() => {
                    CreateCustomer();
                  }}
                  className="w-full flex align-middle justify-center cursor-pointer bg-[var(--primary-color)] font-Poppins text-white rounded-lg p-3 mb-9"
                >
                  {loading ? <ClipLoader color="#fff" size={20} /> : "Continue"}
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full h-auto max-lg:hidden">
          <img
            src="/media/images/pep/web-img/Rectangle 9.png"
            className="w-full h-full block"
            alt="sign-up-img"
          />
        </div>
      </div>
    </section>
  );
}

export default SignUpForm;
