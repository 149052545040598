import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login from "./auth/login";
import { getAuth } from "./auth/getAuth";

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    login({ email, password })
      .then((_res) => {
        getAuth();
        navigate("/");
      })
      .catch((error) => {
        console.log("login erroror ", error.response);
      });
  };

  return (
    <section className="h-screen flex justify-center items-center m-0 overflow-hidden">
      <img
        onClick={() => {
          navigate("https://dev.360-digital.net/pep/");
        }}
        src="/media/images/pep/web-img/logo.png"
        className="fixed cursor-pointer left-8 top-5"
        alt="logo"
      />
      <div className="flex w-full h-full">
        <div className="w-full flex flex-rows justify-center items-center">
          <div className="w-full max-lg:w-[80%] max-sm:w-[100%] px-10 lg:pr-16">
            <h1 className="font-[Annifont] text-4xl text-[var(--text-color)]">
              Welcome Back
            </h1>
            <p className="font-Cushy_W01 text-[#696969] pt-2 pb-8">
              Welcome back! Please enter your details.
            </p>
            <form action="">
              <div className="mb-8">
                <label
                  htmlFor="email"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-gray-600 placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="mb-8">
                <label
                  htmlFor="email"
                  className="text-[var(--text-color)] font-Poppins"
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full font-Cushy_W01 rounded-lg border border-[#696969] py-2 px-3 mt-2 text-[var(--text-color)] placeholder:text-gray-600 placeholder:font-Cushy_W01  sm:text-sm sm:leading-6"
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className="flex justify-between items-center mb-8">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="custom-form-checkbox text-indigo-600 h-4 w-4"
                    required
                  />
                  <label
                    htmlFor="remember-me"
                    className="font-Cushy_W01 text-[var(--text-color)] px-2"
                  >
                    Remember me
                  </label>
                </div>
                <p className="font-Cushy_W01 text-[var(--primary-color)]">
                  Forgot Password
                </p>
                {/* Link need */}
              </div>
              <div className="w-full">
                <p
                  onClick={() => {
                    handleLogin();
                  }}
                  // to="/account-detail"
                  className=" cursor-pointer w-full bg-[var(--primary-color)] font-Poppins text-center text-white rounded-lg p-3 mb-9"
                >
                  Sign In
                </p>
              </div>
              <div className="w-full flex justify-center font-Cushy_W01">
                <p className="text-[var(--text-color)]">
                  Don't have an account?{" "}
                  <Link to="/sign-up">
                    <span className="text-[var(--primary-color)]">Sign up</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full h-auto max-lg:hidden">
          <img
            src="/media/images/pep/web-img/Rectangle 9.png"
            className="w-full h-full block"
            alt="sign-up-img"
          />
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
