import React, { useState } from "react";
import ItemsCategory from "./ItemsCategory";
import ItemsCards from "./ItemsCards";

function Items() {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <section className="xl:py-20 xl:px-16 max-xl:py-20 max-xl:px-2 relative">
      <div className="max-w-[1440px] lg:m-auto flex relative gap-2">
        <ItemsCategory
          setProductList={setProductList}
          setLoading={setLoading}
          showSidebar={showSidebar}
          toggleSidebar={toggleSidebar}
        />

        <ItemsCards
          loading={loading}
          productList={productList}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </section>
  );
}

export default Items;
