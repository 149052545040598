import axios from "axios";
import { isValidToken, setToken } from "./jwt";
import { getAuth } from "./getAuth";
import { MAIN_API_END_POINT } from "../../constant/data";

const login = async (payload) => {
  try {
    const response = await axios.post(
      `${MAIN_API_END_POINT}web/auth/login/`,
      payload
    );
    if (response.status === 200) {
      if (isValidToken(response.data.access)) {
        localStorage.setItem("accessToken", response.data.access);
        setToken(response.data.access);
        getAuth();
      } else {
        console.error("Invalid Token!");
      }
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export default login;
