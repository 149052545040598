import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fCurrencyMMK } from "../../utils/FormatNumber";
import { INITIAL_ORDER } from "../../@types/order";
import { v4 as uuidv4 } from "uuid";
import { ClipLoader } from "react-spinners";
import { createNewOrder } from "../../components/order/order";
import axios from "axios";
import { API_END_POINT } from "../../constant/data";
import useLocales from "../../hook/uselocales";
import login from "../../components/auth/login";

function Cash(props) {
  const { translate } = useLocales();
  const { state } = props;
  const navigate = useNavigate();
  const items = useSelector((state) => state.cart.items);
  const [totalAmount, setTotalAmount] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(INITIAL_ORDER);
  const [isLogin, setIsLogin] = useState(false);
  const [localCustomer, setLocalCustomer] = useState(
    localStorage.getItem("customer")
  );
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [productData, setProductData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    phone: "",
    address: "",
    state: "",
    city: "",
    map: "",
  });

  const fetchPaymentMethods = async () => {
    const response = await axios.get(`${API_END_POINT}web/payment-methods/`);
    response.data.map((payment, index) => {
      if (payment.name === "Cash") {
        setPaymentMethods(payment);
      }
    });
  };

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem("customer"));
    const product = JSON.parse(localStorage.getItem("product"));
    setLocalCustomer(customer);
    setProductData(product);
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (state !== null) {
      setShippingAddress((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.name = state.customer.name;
        newState.phone = state.customer.phone_number;
        newState.address = state.orderAddress.address;
        newState.state = state.orderAddress.state;
        newState.city = state.orderAddress.city;
        return newState;
      });

      setOrder((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.order_address = state?.orderAddress;
        newState.shipping_address = shippingAddress;
        newState.customer_name = state.customer.name;
        newState.due_amount = totalAmount;
        newState.email = state.customer.email;
        newState.note = note;
        newState.password = state.customer.password;
        newState.password_confirm = state.customer.password;
        newState.phone = state.customer.phone_number;
        newState.products = state.selectedProduct;
        newState.subtotal = totalAmount;
        newState.total = totalAmount;
        newState.selected_payment_method = paymentMethods;
        newState.username =
          state.customer.name.toLowerCase().replaceAll(" ", "_") +
          "_" +
          uuidv4();
        return newState;
      });
    } else {
      setIsLogin(true);
    }
  }, [totalAmount, paymentMethods, note, state]);

  useEffect(() => {
    if (isLogin && localCustomer) {
      setShippingAddress((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.name = localCustomer.name;
        newState.phone = localCustomer.phone;
        newState.address = localCustomer.customer_addresses[0].address;
        newState.state = localCustomer.customer_addresses[0].state;
        newState.city = localCustomer.customer_addresses[0].city;
        return newState;
      });

      setOrder((preState) => {
        let newState = JSON.parse(JSON.stringify(preState));
        newState.order_address = shippingAddress;
        newState.shipping_address = shippingAddress;
        newState.customer_name = localCustomer?.name;
        newState.due_amount = totalAmount;
        newState.email = localCustomer?.email;
        newState.note = note;
        newState.phone = localCustomer?.phone;
        newState.products = items;
        newState.subtotal = totalAmount;
        newState.total = totalAmount;
        newState.selected_payment_method = paymentMethods;
        newState.username = localCustomer?.user?.username;
        return newState;
      });
    }
  }, [note, totalAmount, isLogin, localCustomer, paymentMethods]);

  useEffect(() => {
    let totalAmountArrays = [];
    productData.map((product, index) => {
      totalAmountArrays.push(product.subtotal);
    });
    setTotalAmount(
      totalAmountArrays.reduce(
        (partialSum, a) => Number(partialSum) + Number(a),
        0
      )
    );
  }, [productData]);

  const CreateOrder = () => {
    setLoading(true);
    createNewOrder(order)
      .then((data) => {
        if (isLogin === false) {
          login({
            email: state.customer.email,
            password: state.customer.password,
          });
        }
        setLoading(false);
        localStorage.removeItem("product");
        navigate("/confirm-order");
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <>
      <section className="w-screen flex flex-col items-center mt-6 gap-5">
        <div className="w-full flex flex-col gap-5">
          <div className="w-[35%]">
            <div className="w-full flex flex-wrap justify-start gap-40 font-Cushy_W01 text-[var(--text-color)] text-sm my-5">
              <p>Order Total</p>
              <p className="font-Cushy text-base">
                {fCurrencyMMK(totalAmount)} MMK
              </p>
            </div>
            <div className="sm:col-span-3 mt-9">
              <label htmlFor="address" className="block accDetail-form-label">
                {translate("remark")}
              </label>
              <div className="mt-2">
                <textarea
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  name="address"
                  id="address"
                  cols="20"
                  rows="5"
                  placeholder="ပစ်မချပါနဲ့"
                  required
                  className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-Cushy_W01 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                ></textarea>
              </div>
            </div>
            <p className="font-Cushy_W01 text-[var(--primary-color)] py-6">
              <span className="font-PepMyanmar">
                {translate("delifreeabout")}
              </span>
            </p>
            <div className="w-full flex justify-center">
              <p
                className="my-4 cursor-pointer py-4 px-10 font-Cushy_W01 text-white text-sm bg-[var(--primary-color)] rounded-xl flex items-center justify-center"
                style={{ width: "175px", height: "55px" }}
                onClick={() => {
                  CreateOrder();
                }}
              >
                {loading ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  <>{translate("makePayment")}</>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cash;
